@charset "UTF-8";
.error404 {
  /* ---------------------------
 *  ランキング
 * --------------------------------------------------------------------------------*/
}

.error404 .error {
  width: 1200px;
  margin: 0 auto;
  font-size: 1.4rem;
  letter-spacing: .1em;
  line-height: 1.7;
  /* ---------------------------
  *  検索
  * --------------------------------------------------------------------------------*/
}

.error404 .error .message_blk h1 {
  font-size: 2.8rem;
  text-align: center;
  margin-bottom: 35px;
  line-height: 1.5;
}

.error404 .error .message_blk .lead_txt {
  padding: 0 352px;
  letter-spacing: .05em;
  margin-bottom: 80px;
}

.error404 .error .message_blk .large_txt {
  font-size: 2.8rem;
  line-height: 1.4;
  font-weight: 600;
  text-align: center;
}

.error404 .error .search_blk {
  width: 708px;
  margin: 60px auto 120px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk {
  margin-bottom: 75px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk {
  width: 708px;
  margin: 40px auto 0;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk form {
  display: block;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner {
  display: flex;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_input {
  width: 544px;
  border-bottom: 1px solid #8E8A8C;
  padding-left: 10px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_input input[type="text"] {
  width: 100%;
  height: 47px;
  padding: 0 26px 0 0;
  line-height: 47px;
  font-size: 1.6rem;
  outline: none;
  margin-bottom: 10px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_btn {
  width: 160px;
  height: 50px;
  margin-left: 4px;
  position: relative;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_btn:before {
  content: "";
  display: block;
  width: 25px;
  height: 25px;
  background-image: url(../../img/common/ico_search_w.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  top: 14px;
  left: 28px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_btn input[type="submit"] {
  width: 100%;
  height: 100%;
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  padding-left: 28px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_keyword {
  width: 708px;
  margin: 40px auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_keyword span {
  margin-right: 15px;
  margin-bottom: 15px;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_keyword span a {
  height: 32px;
  line-height: 32px;
  border: 1px solid #000;
  padding: 0 16px;
  font-size: 1.4rem;
  font-weight: bold;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_keyword span a::before {
  content: "#";
  margin-right: .2em;
}

.error404 .error .search_blk .menu_search .h_slidedown_blk .h_keyword span a:hover {
  background-color: #000;
  color: #fff;
}

.error404 .error .search_blk .h_keyword {
  width: 100%;
  margin: 0 auto 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.error404 .error .search_blk .h_keyword span {
  margin-right: 15px;
  margin-bottom: 15px;
}

.error404 .error .search_blk .h_keyword span a {
  display: block;
  height: 32px;
  line-height: 32px;
  border: 1px solid #000;
  padding: 0 2.93333vw;
  font-size: 1.4rem;
  font-weight: bold;
}

.error404 .error .search_blk .h_keyword span a::before {
  content: "#";
  margin-right: .2em;
}

.error404 .error .search_blk .h_keyword span a:hover {
  background-color: #000;
  color: #fff;
  opacity: 1;
}

.error404 .error .search_blk .btn01 a {
  min-width: 350px;
}

.error404 #contents {
  width: 100%;
}

.error404 #contents .ranking_items {
  width: 100%;
  max-width: 1592px;
  margin: 0 auto 100px;
}

.error404 #contents .ranking_items .ttl01 {
  margin-bottom: 20px;
}

.error404 #contents .ranking_items .ranking_items_slider {
  width: 100%;
  max-width: 1461px;
  margin: 0 auto 50px;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide {
  margin: 0 34px;
  padding-top: 8px;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide a {
  display: block;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk {
  width: 100%;
  margin-bottom: 14px;
  position: relative;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk img {
  display: block;
  width: 100%;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon {
  width: 40px;
  height: 40px;
  background-color: #000;
  color: #fff;
  font-size: 2.2rem;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: -8px;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon.ranking_1st {
  background-color: #ac8959;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon.ranking_2nd {
  background-color: #7d7d7d;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon.ranking_3rd {
  background-color: #643b14;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk span {
  display: block;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_name {
  font-size: 1.4rem;
  line-height: 1.4;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_price {
  font-size: 1.4rem;
  line-height: 1.4;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-arrow {
  display: block;
  width: 16px;
  height: 26px;
  background-image: url(../../img/common/arrow_b.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 108px;
  z-index: 1;
  cursor: pointer;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-arrow.prev-arrow {
  transform: rotate(180deg);
  left: -48px;
}

.error404 #contents .ranking_items .ranking_items_slider .slick-arrow.next-arrow {
  right: -48px;
}

@media only screen and (min-width: 1221px) and (max-width: 1600px) {
  #error404_dynamic #contents .ranking_items {
    max-width: 1160px;
  }
  #error404_dynamic #contents .ranking_items .ranking_items_slider {
    max-width: 1160px;
  }
  #error404_dynamic #contents .ranking_items .ranking_items_slider .slick-slide {
    margin: 0 20px;
    padding-top: 8px;
  }
  #error404_dynamic #contents .ranking_items .ranking_items_slider .slick-arrow {
    top: 98px;
  }
  #error404_dynamic #contents .ranking_items .ranking_items_slider .slick-arrow.prev-arrow {
    left: -20px;
  }
  #error404_dynamic #contents .ranking_items .ranking_items_slider .slick-arrow.next-arrow {
    right: -20px;
  }
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
  .error404 #contents .error {
    width: 100%;
  }
  .error404 #contents .error .message_blk .lead_txt {
    padding: 0 calc((100% - 496px) / 2);
    margin-bottom: 80px;
  }
  .error404 #contents .ranking_items {
    margin: 0 auto 10vw;
  }
  .error404 #contents .ranking_items .ttl01 {
    margin-bottom: 2vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider {
    margin: 0 auto 5vw;
    padding: 0 4vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-slide {
    margin: 0 2vw;
    padding-top: 0.8vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk {
    margin-bottom: 1.4vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-slide .img_blk .ranking_icon {
    width: 4vw;
    height: 4vw;
    font-size: 2.2vw;
    top: -0.8vw;
    left: -0.8vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_name {
    font-size: 1.4vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-slide .txt_blk .item_price {
    font-size: 1.4vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-arrow {
    width: 1.6vw;
    height: 2.6vw;
    top: 9.2vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-arrow.prev-arrow {
    left: 2vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider .slick-arrow.next-arrow {
    right: 2vw;
  }
}

@media only screen and (max-width: 750px) {
  .error404 #contents .error {
    width: 100vw;
  }
  .error404 #contents .error .message_blk h1 {
    margin: 10.66667vw 0 8vw;
    font-size: 5.33333vw;
  }
  .error404 #contents .error .message_blk .lead_txt {
    padding: 0 5.33333vw;
    letter-spacing: .05em;
    margin-bottom: 10.66667vw;
  }
  .error404 #contents .error .message_blk .large_txt {
    font-size: 1.8rem;
  }
  .error404 #contents .error .search_blk {
    width: 100%;
    margin: 0;
    padding: 7.46667vw 10.66667vw 16vw;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk {
    margin-bottom: 8vw;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk {
    width: 100%;
    margin: 0 auto;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk form {
    display: block;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner {
    display: block;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_input {
    width: 100%;
    border-bottom: 1px solid #8E8A8C;
    padding-left: 1.33333vw;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_input input[type="text"] {
    width: 100%;
    height: 6.66667vw;
    padding: 0 2.4vw 0 0;
    line-height: 6.66667vw;
    font-size: 3.2vw;
    outline: none;
    margin-bottom: 1.33333vw;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_btn {
    width: 37.33333vw;
    height: 10.13333vw;
    margin: 6.13333vw auto 8vw;
    position: relative;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_btn:before {
    content: "";
    display: block;
    width: 4.26667vw;
    height: 4.26667vw;
    background-image: url(../../img/common/ico_search_w.png);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: 2.93333vw;
    left: 7.2vw;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_search_blk .search_inner .search_btn input[type="submit"] {
    width: 100%;
    height: 100%;
    background-color: #000;
    border: 1px solid #000;
    color: #fff;
    font-weight: bold;
    font-size: 3.2vw;
    padding-left: 2.66667vw;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_keyword {
    width: 100%;
    margin: 0 auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_keyword span {
    margin-right: 3.46667vw;
    margin-bottom: 3.46667vw;
    letter-spacing: 0;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_keyword span a {
    height: 7.33333vw;
    line-height: 7.33333vw;
    border: 1px solid #000;
    padding: 0 2.93333vw;
    font-size: 3.2vw;
    font-weight: bold;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_keyword span a::before {
    content: "#";
    margin-right: .2em;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_keyword span a:hover {
    background-color: #000;
    color: #fff;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_menu_popup_btn_close {
    width: 37.33333vw;
    height: 10vw;
    margin: 8.53333vw auto 0;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_menu_popup_btn_close a {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #000;
    color: #fff;
    font-size: 3.2vw;
    letter-spacing: .1em;
    text-align: center;
    line-height: 10vw;
    font-weight: bold;
  }
  .error404 #contents .error .search_blk .menu_search .h_slidedown_blk .h_menu_popup_btn_close a::before {
    content: "";
    display: inline-block;
    width: 2.66667vw;
    height: 2.66667vw;
    background-image: url(../../img/common/close_w.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    margin-right: 3.73333vw;
  }
  .error404 #contents .error .search_blk .btn01 a {
    min-width: 48vw;
  }
  .error404 #contents .ranking_items {
    margin: 0 auto 12vw;
  }
  .error404 #contents .ranking_items .ttl01 {
    margin-bottom: 2.4vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider {
    display: flex;
    overflow-x: scroll;
    margin: 0 auto 12vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider::after {
    content: "";
    display: block;
    width: 5.33333vw;
    height: 1px;
    flex-shrink: 0;
  }
  .error404 #contents .ranking_items .ranking_items_slider li {
    width: 34.66667vw;
    flex-shrink: 0;
    margin: 0 0 0 5.33333vw;
    padding-top: 1.33333vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .img_blk {
    width: 100%;
    margin-bottom: 2.66667vw;
    position: relative;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .img_blk img {
    display: block;
    width: 100%;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon {
    width: 6.53333vw;
    height: 6.53333vw;
    background-color: #000;
    color: #fff;
    font-size: 3.46667vw;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    top: -1.33333vw;
    left: -1.33333vw;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon.ranking_1st {
    background-color: #ac8959;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon.ranking_2nd {
    background-color: #7d7d7d;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .img_blk .ranking_icon.ranking_3rd {
    background-color: #643b14;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .txt_blk span {
    display: block;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .txt_blk .item_name {
    font-size: 3.2vw;
    line-height: 1.33;
  }
  .error404 #contents .ranking_items .ranking_items_slider li .txt_blk .item_price {
    font-size: 3.2vw;
    line-height: 1.33;
  }
}

#error404_static #contents .link_blk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 840px;
  margin: 80px auto;
}

#error404_static #contents .link_blk a {
  margin: 0 auto;
}

#error404_static #contents .btn_blk a {
  margin: 0 auto;
}

#error404_static #contents .btn_blk.back_btn {
  margin-top: 80px;
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
  #error404_static #contents .link_blk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 80px 0 0;
  }
  #error404_static #contents .link_blk a {
    margin: 0 20px;
  }
  #error404_static #contents .btn_blk a {
    margin: 0 auto;
  }
  #error404_static #contents .btn_blk.back_btn {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 750px) {
  #error404_static #contents .link_blk {
    display: block;
    width: 100%;
    margin: 11.06667vw auto 0;
    padding: 0 5.33333vw;
  }
  #error404_static #contents .link_blk a {
    margin: 0 auto 5.33333vw;
  }
  #error404_static #contents .btn_blk {
    width: 100%;
  }
  #error404_static #contents .btn_blk a {
    margin: 0 auto;
  }
  #error404_static #contents .btn_blk.back_btn {
    margin-top: 5.33333vw;
  }
}
