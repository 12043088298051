// =================================================================================
// error404 共通
// =================================================================================
.error404 {
  .error{
    width: 1200px;
    margin: 0 auto;
    font-size: 1.4rem;
    letter-spacing: .1em;
    line-height: 1.7;
    .message_blk{
      h1{
        font-size: 2.8rem;
        text-align: center;
        margin-bottom: 35px;
        line-height: 1.5;
      }
      .lead_txt{
        padding: 0 352px;
        letter-spacing: .05em;
        margin-bottom: 80px;
      }
      .large_txt{
        font-size: 2.8rem;
        line-height: 1.4;
        font-weight: 600;
        text-align: center;
      }
    }
  /* ---------------------------
  *  検索
  * --------------------------------------------------------------------------------*/
    .search_blk{
      width: 708px;
      margin: 60px auto 120px;
      .menu_search {
        .h_slidedown_blk {
          margin-bottom: 75px;
          .h_search_blk {
            width: 708px;
            margin: 40px auto 0;
            form {
              display: block;
            }
            .search_inner {
              display: flex;
              .search_input {
                width: 544px;
                border-bottom: 1px solid #8E8A8C;
                padding-left: 10px;
                input[type="text"] {
                  width: 100%;
                  height: 47px;
                  padding: 0 26px 0 0;
                  line-height: 47px;
                  font-size: 1.6rem;
                  outline: none;
                  margin-bottom: 10px;
                }
              }
              .search_btn {
                width: 160px;
                height: 50px;
                margin-left: 4px;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 25px;
                  height: 25px;
                  background-image: url(../../img/common/ico_search_w.png);
                  background-position: center center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 14px;
                  left: 28px;
                }
                input[type="submit"] {
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                  border: 1px solid #000;
                  color: #fff;
                  font-weight: bold;
                  font-size: 1.6rem;
                  padding-left: 28px;
                }
              }
            }
          }
          .h_keyword {
            width: 708px;
            margin: 40px auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            span {
              margin-right: 15px;
              margin-bottom: 15px;
              a {
                height: 32px;
                line-height: 32px;
                border: 1px solid #000;
                padding: 0 16px;
                font-size: 1.4rem;
                font-weight: bold;
                &::before {
                  content: "#";
                  margin-right: .2em;
                }
                &:hover {
                  background-color: #000;
                  color: #fff;
                }
              }
            }
          }
        }
      }
      .h_keyword {
        width: 100%;
        margin: 0 auto 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        span {
          margin-right: 15px;
          margin-bottom: 15px;
          a {
            display: block;
            height: 32px;
            line-height: 32px;
            border: 1px solid #000;
            padding: 0 22/750*100vw;
            font-size: 1.4rem;
            font-weight: bold;
            &::before {
              content: "#";
              margin-right: .2em;
            }
            &:hover {
              background-color: #000;
              color: #fff;
              opacity: 1;
            }
          }
        }
      }
      .btn01{
        a{
          min-width: 350px;
        }
      }
    }

  }
/* ---------------------------
 *  ランキング
 * --------------------------------------------------------------------------------*/

  #contents {
    width: 100%;
    //ランキング
    .ranking_items {
      width: 100%;
      max-width: 1592px;
      margin: 0 auto 100px;
      .ttl01 {
        margin-bottom: 20px;
      }
      .ranking_items_slider {
        width: 100%;
        max-width: 1461px;
        margin: 0 auto 50px;
        .slick-slide {
          margin: 0 34px;
          padding-top: 8px;
          a {
            display: block;
          }
          .img_blk {
            width: 100%;
            margin-bottom: 14px;
            position: relative;
            img {
              display: block;
              width: 100%;
            }
            .ranking_icon {
              width: 40px;
              height: 40px;
              background-color: #000;
              color: #fff;
              font-size: 2.2rem;
              line-height: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;
              position: absolute;
              top: -8px;
              left: -8px;
              &.ranking_1st {
                background-color: #ac8959;
              }
              &.ranking_2nd {
                background-color: #7d7d7d;
              }
              &.ranking_3rd {
                background-color: #643b14;
              }
            }
          }
          .txt_blk {
            span {
              display: block;
            }
            .item_name {
              font-size: 1.4rem;
              line-height: 1.4;
            }
            .item_price {
              font-size: 1.4rem;
              line-height: 1.4;
            }
          }
        }
        .slick-arrow {
          display: block;
          width: 16px;
          height: 26px;
          background-image: url(../../img/common/arrow_b.png);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          position: absolute;
          top: 108px;
          z-index: 1;
          cursor: pointer;
          &.prev-arrow {
            transform: rotate(180deg);
            left: -48px;
          }
          &.next-arrow {
            right: -48px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1221px) and (max-width: 1600px) {
#error404_dynamic {
  #contents {
    //ランキング
    .ranking_items {
      max-width: 1160px;
      .ranking_items_slider {
        max-width: 1160px;
        .slick-slide {
          margin: 0 20px;
          padding-top: 8px;
        }
        .slick-arrow {
          top: 98px;
          &.prev-arrow {
            left: -20px;
          }
          &.next-arrow {
            right: -20px;
          }
        }
      }
    }
  }
}
}

@media only screen and (min-width: 751px) and (max-width: 1220px) {
.error404 {
  #contents {
    .error{
      width: 100%;
      .message_blk{
        .lead_txt{
          padding: 0 calc((100% - 496px) / 2);
          margin-bottom: 80px;
        }
      }  
    }

    //ランキング
    .ranking_items {
      margin: 0 auto 100/1000*100vw;
      .ttl01 {
        margin-bottom: 20/1000*100vw;
      }
      .ranking_items_slider {
        margin: 0 auto 50/1000*100vw;
        padding: 0 40/1000*100vw;
        .slick-slide {
          margin: 0 20/1000*100vw;
          padding-top: 8/1000*100vw;
          .img_blk {
            margin-bottom: 14/1000*100vw;
            .ranking_icon {
              width: 40/1000*100vw;
              height: 40/1000*100vw;
              font-size: 22/1000*100vw;
              top: -8/1000*100vw;
              left: -8/1000*100vw;
            }
          }
          .txt_blk {
            .item_name {
              font-size: 14/1000*100vw;
            }
            .item_price {
              font-size: 14/1000*100vw;
            }
          }
        }
        .slick-arrow {
          width: 16/1000*100vw;
          height: 26/1000*100vw;
          top: 92/1000*100vw;
          &.prev-arrow {
            left: 20/1000*100vw;
          }
          &.next-arrow {
            right: 20/1000*100vw;
          }
        }
      }
    }
  }
}
}

@media only screen and (max-width: 750px) {
.error404 {
  #contents{
  .error{
    width: 100vw;
    .message_blk{
      h1{
        margin: 10.66667vw 0 8vw;
        font-size: 5.33333vw;
      }
      .lead_txt{
        padding: 0 40 / 750 * 100vw;
        letter-spacing: .05em;
        margin-bottom: 80 / 750 * 100vw;
      }
      .large_txt{
        font-size: 1.8rem;
      }
    }
    .search_blk{
      width: 100%;
      margin: 0;
      padding: 7.46667vw 10.66667vw 120 / 750 * 100vw;
      .menu_search {
        .h_slidedown_blk {
          margin-bottom: 60 / 750 * 100vw;
          .h_search_blk {
            width: 100%;
            margin: 0 auto;
            form {
              display: block;
            }
            .search_inner {
              display: block;
              .search_input {
                width: 100%;
                border-bottom: 1px solid #8E8A8C;
                padding-left: 10/750*100vw;
                input[type="text"] {
                  width: 100%;
                  height: 50/750*100vw;
                  padding: 0 18/750*100vw 0 0;
                  line-height: 50/750*100vw;
                  font-size: 24/750*100vw;
                  outline: none;
                  margin-bottom: 10/750*100vw;
                }
              }
              .search_btn {
                width: 280/750*100vw;
                height: 76/750*100vw;
                margin: 46/750*100vw auto 60/750*100vw;
                position: relative;
                &:before {
                  content: "";
                  display: block;
                  width: 32/750*100vw;
                  height: 32/750*100vw;
                  background-image: url(../../img/common/ico_search_w.png);
                  background-position: center center;
                  background-size: contain;
                  background-repeat: no-repeat;
                  position: absolute;
                  top: 22/750*100vw;
                  left: 54/750*100vw;
                }
                input[type="submit"] {
                  width: 100%;
                  height: 100%;
                  background-color: #000;
                  border: 1px solid #000;
                  color: #fff;
                  font-weight: bold;
                  font-size: 24/750*100vw;
                  padding-left: 20/750*100vw;
                }
              }
            }
          }
          .h_keyword {
            width: 100%;
            margin: 0 auto 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            span {
              margin-right: 26/750*100vw;
              margin-bottom: 26/750*100vw;
              letter-spacing: 0;
              a {
                height: 55/750*100vw;
                line-height: 55/750*100vw;
                border: 1px solid #000;
                padding: 0 22/750*100vw;
                font-size: 24/750*100vw;
                font-weight: bold;
                &::before {
                  content: "#";
                  margin-right: .2em;
                }
                &:hover {
                  background-color: #000;
                  color: #fff;
                }
              }
            }
          }
          .h_menu_popup_btn_close {
            width: 280/750*100vw;
            height: 75/750*100vw;
            margin: 64/750*100vw auto 0;
            a {
              display: block;
              width: 100%;
              height: 100%;
              background-color: #000;
              color: #fff;
              font-size: 24/750*100vw;
              letter-spacing: .1em;
              text-align: center;
              line-height: 75/750*100vw;
              font-weight: bold;
              &::before {
                content: "";
                display: inline-block;
                width: 20/750*100vw;
                height: 20/750*100vw;
                background-image: url(../../img/common/close_w.png);
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center center;
                margin-right: 28/750*100vw;
              }
            }
          }
        }
      }
      .btn01{
        a{
          min-width: 48vw;
        }
      }
    }
  }

  //ランキング
  .ranking_items {
    margin: 0 auto 90/750*100vw;
    .ttl01 {
      margin-bottom: 18/750*100vw;
    }
    .ranking_items_slider {
      display: flex;
      overflow-x: scroll;
      margin: 0 auto 90/750*100vw;
      &::after {
        content: "";
        display: block;
        width: 40/750*100vw;
        height: 1px;
        flex-shrink: 0;
      }
      li {
        width: 260/750*100vw;
        flex-shrink: 0;
        margin: 0 0 0 40/750*100vw;
        padding-top: 10/750*100vw;
        .img_blk {
          width: 100%;
          margin-bottom: 20/750*100vw;
          position: relative;
          img {
            display: block;
            width: 100%;
          }
          .ranking_icon {
            width: 49/750*100vw;
            height: 49/750*100vw;
            background-color: #000;
            color: #fff;
            font-size: 26/750*100vw;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            position: absolute;
            top: -10/750*100vw;
            left: -10/750*100vw;
            &.ranking_1st {
              background-color: #ac8959;
            }
            &.ranking_2nd {
              background-color: #7d7d7d;
            }
            &.ranking_3rd {
              background-color: #643b14;
            }
          }
        }
        .txt_blk {
          span {
            display: block;
          }
          .item_name {
            font-size: 24/750*100vw;
            line-height: 1.33;
          }
          .item_price {
            font-size: 24/750*100vw;
            line-height: 1.33;
          }
        }
      }
    }
  }
  }
}
}

// =================================================================================
// error404 静的ページ
// =================================================================================
#error404_static {
  #contents{
    .link_blk {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 840px;
      margin: 80px auto;
      a {
          margin: 0 auto;
      }
    }
    .btn_blk {
        a {
            margin: 0 auto;
        }
        &.back_btn {
            margin-top: 80px;
        }
    }
  }
}


@media only screen and (min-width: 751px) and (max-width: 1220px) {
#error404_static {
  #contents {
    .link_blk {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 80px 0 0;
      a {
          margin: 0 20px;
      }
    }
    .btn_blk {
        a {
            margin: 0 auto;
        }
        &.back_btn {
            margin-top: 50px;
        }
    }
  }
}
}

@media only screen and (max-width: 750px) {
  #error404_static{
    #contents{
      .link_blk {
        display: block;
        width: 100%;
        margin: 83 / 750 * 100vw auto 0;
        padding: 0 5.33333vw;
        a {
            margin: 0 auto 40 * 100vw / 750;
        }
      }
      .btn_blk {
          width: 100%;
          a {
              margin: 0 auto;
          }
          &.back_btn {
              margin-top: 40 * 100vw / 750;
          }
      }
    }
  }
}